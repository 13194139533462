<template>
  <div class="">
    <top-header></top-header>
    <router-view></router-view>
    <copy-right></copy-right>
    <el-backtop :visibility-height="100" :bottom="60"></el-backtop>
  </div>
</template>

<script>
import topHeader from "@/components/header.vue";
import copyRight from "@/components/copyRight.vue";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  components: {
    topHeader,
    copyRight
  }
};
</script>

<style scoped lang="less">
</style>
