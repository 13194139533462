<template>
  <div class="copyright">
    <div class="wave" v-if="routerName =='/home'">
      <div class="shape shape3"></div>
      <div class="shape shape2"></div>
      <div class="shape shape1"></div>
    </div>
    <div class="company-box">
      <div class="company wrapper">
        <div class="company-l">
          <div class="c-l-t">
            关注我们
            <el-popover placement="top" width="160" trigger="hover">
              <p> <img width="100%" src="@/assets/scan-wx.png" alt=""></p>
              <img slot="reference" src="@/assets/wx.png" alt="">
            </el-popover>
            <!-- <el-link href="mailto:cs@iriets.com" :underline="false"><img src="@/assets/wb.png" alt=""></el-link> -->
            <!-- mailto:cs@iriets.com -->
            <el-link href="mailto:cs@iriets.com" :underline="false"><img src="@/assets/yx.png" alt=""></el-link>

          </div>
          <div class="c-l-b">
            <p style="margin-bottom:15px">南京易锐思科技有限公司</p>
            <p>地址：江苏省南京市雨花台区宁双路沁恒科技园D栋205</p>
            <p>电话：025 5260 0022</p>
            <p>邮箱：cs@iriets.com</p>
          </div>
        </div>
        <div class="company-r">
          <div id="container"></div>
        </div>
      </div>
      <p class="wrapper cr">© 2023 南京易锐思科技有限公司 <a href="https://beian.miit.gov.cn" target="_blank">ICP备案编号：苏ICP备17030533号</a></p>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      routerName: ""
    };
  },
  watch: {
    $route() {
      this.routerName = this.$route.path;
      console.log(this.$route.path);
    }
  },
  created() {
    // 将标注添加到地图中
  },
  mounted() {
    var map = new BMap.Map("container");
    var point = new BMap.Point(118.76239, 31.978739);
    map.addControl(new BMap.NavigationControl());
    map.addControl(new BMap.ScaleControl());
    map.addControl(new BMap.OverviewMapControl());
    map.centerAndZoom(point, 15);
    var marker = new BMap.Marker(point); // 创建标注
    map.addOverlay(marker);
  },
  methods: {},
  components: {}
};
</script>

<style scoped lang="less">
.cr {
  text-align: center;
  a {
    text-decoration: underline;
  }
}
</style>
