<template>
  <div class="iriets-header">
    <div class="wrapper flex">
      <a class="logo" href="/">
        <img src="@/assets/logo_1.png" alt="">
      </a>
      <el-menu :default-active='$route.path' router class="el-menu-demo" mode="horizontal">
        <el-menu-item index="/index">首页</el-menu-item>
        <el-submenu index="2">
          <template slot="title">产品</template>
          <el-menu-item index="/product/8">智能物联网平台</el-menu-item>
          <el-menu-item index="/product/10">趣游园智慧景区SaaS管理平台</el-menu-item>
          <el-menu-item index="/product/9">易苍穹无人机调度平台</el-menu-item>
          <el-menu-item index="/product/1">SOM智能运维平台</el-menu-item>
          <el-menu-item index="/product/2">DICT集中运维平台</el-menu-item>
          <el-menu-item index="/product/3">智能审核平台</el-menu-item>
          <el-menu-item index="/product/11">智慧物管系统</el-menu-item>
          <!-- <el-menu-item index="/product/4">燃机进气选型系统</el-menu-item> -->
          <!-- <el-menu-item index="/product/5">数据展厅系统</el-menu-item> -->
          <el-menu-item index="/product/6">标签信息管理系统</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">核心技术</template>
          <el-menu-item index="/technology/1">数字孪生</el-menu-item>
          <el-menu-item index="/technology/2">数据治理</el-menu-item>
          <el-menu-item index="/technology/3">智慧运维</el-menu-item>
          <el-menu-item index="/technology/4">SaaS平台</el-menu-item>
        </el-submenu>
        <el-menu-item index="/about">关于我们</el-menu-item>
      </el-menu>
      <div class="nav_btn" @click="drawer = true"><span></span></div>
      <el-drawer title="" size="80%" :visible.sync="drawer" direction="ltr">
        <template slot="title">
          <div>
            <img style="width: 90px;" src="@/assets/logo_1.png" alt="">
          </div>
        </template>
        <div class="m-menu-list">
          <el-menu router :unique-opened="true" class="el-menu-demo-vertical">
            <el-menu-item index="/index">首页</el-menu-item>
            <el-submenu index="2">
              <template slot="title">产品</template>
              <el-menu-item index="/product/8">智能物联网平台</el-menu-item>
              <el-menu-item index="/product/10">趣游园智慧景区SaaS管理平台</el-menu-item>
              <el-menu-item index="/product/9">易苍穹无人机调度平台</el-menu-item>
              <el-menu-item index="/product/1">SOM智能运维平台</el-menu-item>
              <el-menu-item index="/product/2">DICT集中运维平台</el-menu-item>
              <el-menu-item index="/product/3">智能审核平台</el-menu-item>
              <el-menu-item index="/product/11">智慧物管系统</el-menu-item>
              <!-- <el-menu-item index="/product/4">燃机进气选型系统</el-menu-item> -->
              <!-- <el-menu-item index="/product/5">数据展厅系统</el-menu-item> -->
              <el-menu-item index="/product/6">标签信息管理系统</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">核心技术</template>
              <el-menu-item index="/technology/1">数字孪生</el-menu-item>
              <el-menu-item index="/technology/2">数据治理</el-menu-item>
              <el-menu-item index="/technology/3">智慧运维</el-menu-item>
              <el-menu-item index="/technology/4">SaaS平台</el-menu-item>
            </el-submenu>
            <el-menu-item index="/about">关于我们</el-menu-item>
          </el-menu>
        </div>
      </el-drawer>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false
    };
  },
  created() {},
  watch: {
    $route() {
      this.drawer = false;
    }
  },
  methods: {},
  components: {}
};
</script>

<style scoped lang="less">
.iriets-header {
  height: 60px;
  .logo {
    img {
      margin-top: 17px;
      width: 86px;
      height: 26px;
    }
  }
  .el-menu-demo {
    padding-left: 60px;
    flex: 1;
    border: none;
  }
}
</style>
